import React from "react"
import "./styles.css"
import classNames from "classnames"

const Checkbox = React.forwardRef(
  ({ children, name, color, round, className, onClick, ...props }, ref) => {
    const classList = classNames(
      "wt-checkbox",
      `wt-checkbox--${color}`,
      { [`wt-checkbox--round`]: round },
      className
    )

    return (
      <div className={classList} onClick={onClick}>
        <input type="checkbox" {...props} ref={ref} />
        <span className="wt-checkbox__mark" />
        {children}
      </div>
    )
  }
)

export default Checkbox
