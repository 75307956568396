import React from "react"
import "./styles.css"
import ArtImage from "../../../images/art4.png"
import { useTranslation } from "../../../utils"

const PentestTeam = () => {
  const { t } = useTranslation()

  return (
    <section className="pentest-team">
      <div className="container wt-relative">
        <img src={ArtImage} alt="art" className="pentest-team__art" />

        <h2 className="pentest-team__title">{t("Our team")}</h2>

        <div className="pentest-team__row">
          <div>
            <h3>{t("Professionals")}</h3>
            <p>
              {t(
                "Battle-tested OSCP/OSCE certified security engineers are at your service."
              )}
            </p>
          </div>
          <div>
            <h3>{t("Client base")}</h3>
            <p>
              {t(
                "We built our expertise and reputation on over hundreds of clients globally."
              )}
            </p>
          </div>
          <div>
            <h3>{t("Awards")}</h3>
            <p>
              {t(
                "We have been winning several international competitions for the last 3 years (PHDays)."
              )}
            </p>
          </div>
        </div>
        <div className="pentest-team__row">
          <div>
            <h3>{t("R&D")}</h3>
            <p>
              {t(
                "We have our own research studies, publications, and certificates."
              )}
            </p>
          </div>
          <div>
            <h3>{t("Community")}</h3>
            <p>
              {t(
                "We are the founders of the biggest hackers community in Central Asia (Kaz’Hack’Stan)."
              )}
            </p>
          </div>
          <div />
        </div>
      </div>
    </section>
  )
}

export default PentestTeam
