import React from "react"
import "./styles.css"
import { useTranslation } from "../../../utils"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import FriendlyIcon from "../../../images/friendly.svg"
import Img from "gatsby-image"

const PentestBenefits = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "pentest_man.png" }) {
        childImageSharp {
          fluid(maxWidth: 555) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="container pentest-benefits">
      <div className="wt-flex">
        <div className="wt-flex-1">
          <div className="pentest-benefits__title">
            <FormattedMessage
              id="What are the benefits"
              defaultMessage="What are the benefits"
              values={{
                div: (...chunks) => <div>{chunks} </div>,
                blue: (...chunks) => (
                  <div className="wt-text wt-text--blue wt-text--bold">
                    {chunks}
                  </div>
                ),
              }}
            />
          </div>

          <div
            className="pentest-benefits__block"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <img
              src={FriendlyIcon}
              alt="Friendly"
              className="wt-margin-right-30"
            />
            <div className="wt-flex-column">
              <div className="pentest-benefits__description">
                {t(
                  "Get a comprehensive independent view of your security status."
                )}
              </div>
            </div>
          </div>
          <div
            className="pentest-benefits__block"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            <img
              src={FriendlyIcon}
              alt="Friendly"
              className="wt-margin-right-30"
            />
            <div className="wt-flex-column">
              <div className="pentest-benefits__description">
                {t("Understand and reduce the cyber risks for your business.")}
              </div>
            </div>
          </div>
          <div
            className="pentest-benefits__block"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <img
              src={FriendlyIcon}
              alt="Friendly"
              className="wt-margin-right-30"
            />
            <div className="wt-flex-column">
              <div className="pentest-benefits__description">
                {t(
                  "Identify business logic flaws that can not be found by automated scanners."
                )}
              </div>
            </div>
          </div>
          <div
            className="pentest-benefits__block"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
            <img
              src={FriendlyIcon}
              alt="Friendly"
              className="wt-margin-right-30"
            />
            <div className="wt-flex-column">
              <div className="pentest-benefits__description">
                {t(
                  "Avoid unnecessary IT security costs by implementing only relevant security recommendations."
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="wt-flex-1 pentest-benefits__image"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default PentestBenefits
