import React from "react"
import "./styles.css"
import PentestDashboard from "../../../images/pentest_dashboard.png"
import ArtImage from "../../../images/art4.png"
import { useTranslation } from "../../../utils"

const PentestSection = () => {
  const { t } = useTranslation()

  return (
    <section className="pentest-section">
      <div className="container wt-flex wt-flex-middle wt-relative">
        <div className="pentest-section__content">
          <h1 className="pentest-section__title">
            {t("Web-Application Penetration Testing")}
          </h1>
          <p className="pentest-section__text">
            {t(
              "Penetration testing is a real-time attack on your digital assets to reveal security weaknesses or loopholes in your infrastructure. This is a single way to find out what hackers could access within your website or network."
            )}
          </p>
          <p className="pentest-section__text">
            {t(
              "We help you to detect and fix both high and low risk vulnerabilities to ensure that your digital assets are as secure as they can be."
            )}
          </p>
          <img src={ArtImage} alt="art1" className="pentest-section__art1" />
          <img src={ArtImage} alt="art2" className="pentest-section__art2" />
          <img
            src={PentestDashboard}
            alt="dashboard"
            className="pentest-section__image"
          />
        </div>
      </div>
    </section>
  )
}

export default PentestSection
