import React from "react"
import "./styles.css"
import PentestLookImage from "../../../images/pentest_look.png"
import { useTranslation } from "../../../utils"
import { FormattedMessage } from "gatsby-plugin-intl"

const PentestReason = () => {
  const { t } = useTranslation()
  return (
    <section className="pentest-reason container">
      <div className="pentest-reason__box">
        <div className="pentest-reason__box-item pentest-reason__box-item--border-bottom">
          <h2 className="pentest-reason__title">
            <FormattedMessage
              id="Why do you need it"
              defaultMessage="Why do you need it"
              values={{
                div: (...chunks) => <div>{chunks} </div>,
                blue: (...chunks) => (
                  <div className="wt-text wt-text--blue">{chunks}</div>
                ),
              }}
            />
          </h2>

          <img
            src={PentestLookImage}
            alt=""
            className="pentest-reason__image"
          />
        </div>
        <div className="pentest-reason__box-items">
          <div className="pentest-reason__box-item">
            <h4 className="pentest-reason__percent">86%</h4>
            <p className="pentest-reason__description">
              {t(
                "86% of web resources contain at least one critical vulnerability. Their experts advise regular pentests."
              )}
            </p>
          </div>
          <div className="pentest-reason__box-item pentest-reason__box-item--border-left">
            <h4 className="pentest-reason__percent">67%</h4>
            <p className="pentest-reason__description">
              {t(
                "The number of web applications with critical vulnerabilities is 67%."
              )}
            </p>
          </div>
          <div className="pentest-reason__box-item pentest-reason__box-item--border-left">
            <h4 className="pentest-reason__percent">57.9%</h4>
            <p className="pentest-reason__description">
              {t(
                "Vulnerabilities with a high level of risk and higher are found in 57.9% of web applications."
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PentestReason
