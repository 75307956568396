import React, { useState } from "react"
import "./styles.css"
import ArtImage from "../../../images/pentest_form_art.png"
import Input from "../../../components/input"
import Checkbox from "../../../components/checkbox"
import { useForm } from "react-hook-form"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import { toast } from "react-toastify"
import { useTranslation } from "../../../utils"
import { CONFIG } from "../../../config"
import classNames from "classnames"
import { useAnalytics } from "../../../hooks/useTracking"

const PentestForm = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, watch } = useForm()
  const [privacy, setPrivacy] = useState(false)
  const watchFields = watch([
    "first_name",
    "last_name",
    "company",
    "website",
    "email",
    "phone",
    "message",
  ])
  const analytics = useAnalytics()
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const phonePattern = /[\+()]*(?:\d[\s\-\.()xX]*){10,14}/
  const urlPattern = /(http(s)?:\/\/)?(www\.)?[A-Za-z0-9]+\.[a-z]{2,3}/

  const onSubmit = async data => {
    if (privacy) {
      let url = CONFIG?.PENTEST_DEMO_API_URL
      const {
        first_name,
        last_name,
        company,
        email,
        phone,
        message,
        website,
      } = data

      let param = {
        first_name,
        last_name,
        company,
        email,
        phone,
        message,
        website,
      }

      analytics.sendMessage(
        first_name,
        last_name,
        email,
        website,
        company,
        phone,
        message
      )

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
        .then(result => result.json())
        .then(response => {
          if (response.response.status === 0) toast("Demo request - success")
          if (response?.error) {
            switch (response.error.message) {
              case "INVALID_INPUT":
                toast(t("Demo request INVALID_INPUT"))
                break
              case "INTERNAL_ERROR":
                toast(t("Demo request INTERNAL_ERROR"))
              default:
                throw new Error()
            }
          }
        })
        .catch(() => toast("Something went wrong, please try again later"))
    } else {
      toast(
        "Please indicate that you have read and agree to the  Privacy Policy"
      )
    }
  }

  const handler = () => {
    analytics.confirmPolicy(!privacy)
    setPrivacy(!privacy)
  }

  return (
    <section className="pentest-form">
      <div className="container wt-flex">
        <form className="pentest-form__form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="pentest-form__title">Order a test</h2>

          <div className="wt-flex wt-margin-bottom-25">
            <Input
              placeholder={t("First name")}
              onBlur={() => analytics.inputFirstName(watchFields.first_name)}
              className={classNames("wt-flex-1", {
                "wt-input_red-border": errors.first_name,
              })}
              name="first_name"
              ref={register({ required: true, maxLength: 301 })}
            />
            <Input
              placeholder={t("Last name")}
              onBlur={() => analytics.inputLastName(watchFields.last_name)}
              className={classNames("wt-flex-1 wt-margin-left-25", {
                "wt-input_red-border": errors.last_name,
              })}
              name="last_name"
              ref={register({ required: true, maxLength: 301 })}
            />
          </div>
          <div className="wt-flex wt-margin-bottom-25">
            <Input
              placeholder="Company name"
              onBlur={() => analytics.inputLastName(watchFields.company)}
              className={classNames("wt-flex-1", {
                "wt-input_red-border": errors.company,
              })}
              name="company"
              ref={register({ required: true, maxLength: 150 })}
            />
            <Input
              placeholder={t("Website")}
              onBlur={() => analytics.INPUT_DOMAIN(watchFields.website)}
              className={classNames("wt-flex-1 wt-margin-left-25", {
                "wt-input_red-border": errors.website,
              })}
              name="website"
              ref={register({ required: true, pattern: urlPattern })}
            />
          </div>
          <div className="wt-flex wt-margin-bottom-25">
            <Input
              onBlur={() => analytics.INPUT_EMAIL(watchFields.email)}
              placeholder={t("E-mail")}
              className={classNames("wt-flex-1", {
                "wt-input_red-border": errors.email,
              })}
              type="email"
              name="email"
              ref={register({ required: true, pattern: emailPattern })}
            />
            <Input
              placeholder={t("Contact number")}
              onBlur={() => analytics.inputContactNumber(watchFields.phone)}
              className={classNames("wt-flex-1 wt-margin-left-25", {
                "wt-input_red-border": errors.phone,
              })}
              name="phone"
              ref={register({ required: true, pattern: phonePattern })}
            />
          </div>
          <Input
            onBlur={() => analytics.inputMessage(watchFields.message)}
            placeholder={t("Message")}
            textarea
            className={classNames("wt-margin-bottom-25", {
              "wt-input_red-border": errors.message,
            })}
            name="message"
            ref={register({ required: true, maxLength: 500 })}
          />

          <div className="wt-flex wt-flex-between">
            <Checkbox
              name="privacy"
              onClick={handler}
              checked={privacy}
              color="green"
            >
              <FormattedMessage
                id="I confirm that I have read and understood the terms set out in Privacy Policy."
                defaultMessage="I confirm that I have read and understood the terms set out in Privacy Policy."
                values={{
                  link: (...chunks) => <Link to="/privacy">{chunks}</Link>,
                }}
              />
            </Checkbox>

            <button className="wt-button wt-button--primary" type="submit">
              {t("Send message")}
            </button>
          </div>
        </form>
        <div className="pentest-form__image">
          <img src={ArtImage} alt="" className="wt-width-1-1" />
        </div>
      </div>
    </section>
  )
}

export default PentestForm
