import React from "react"
import "./styles.css"
import MouseIcon from "../../../images/mouse.svg"
import Mouse2Icon from "../../../images/mouse2.svg"
import ConductingIcon from "../../../images/conducting.svg"
import AimIcon from "../../../images/aim.svg"
import Shield2Icon from "../../../images/shield2.svg"
import NoteIcon from "../../../images/note.svg"
import { useTranslation } from "../../../utils"

const PentestFeatures = () => {
  const { t } = useTranslation()
  return (
    <section className="pentest-features container">
      <h2 className="pentest-features__title">{t("Our features")}</h2>

      <div className="wt-flex wt-flex-middle pentest-features__desktop">
        <div className="pentest-features__column">
          <div className="pentest-features__item">
            <div className="pentest-features__icon">
              <img src={MouseIcon} alt="mouse" />
            </div>
            {t("Manual vulnerability discovery")}
          </div>
        </div>
        <div className="pentest-features__column">
          <div className="pentest-features__item wt-margin-bottom-30">
            <div className="pentest-features__icon">
              <img src={ConductingIcon} alt="mouse" />
            </div>
            {t("Conducting tool checks of applications")}
          </div>
          <div className="pentest-features__item">
            <div className="pentest-features__icon">
              <img src={Shield2Icon} alt="mouse" />
            </div>
            {t("Cyber risks assessment based on the publicly available data")}
          </div>
        </div>
        <div className="pentest-features__column">
          <div className="pentest-features__item wt-margin-bottom-30 wt-margin-top-40">
            <div className="pentest-features__icon">
              <img src={Mouse2Icon} alt="mouse" />
            </div>
            {t(
              "Complementary automated checks by the most reliable vulnerability scanners"
            )}
          </div>
          <div className="pentest-features__item">
            <div className="pentest-features__icon">
              <img src={NoteIcon} alt="mouse" />
            </div>
            {t(
              "Detailed report with the recommendations to eliminate the identified vulnerabilities"
            )}
          </div>
        </div>
        <div className="pentest-features__column">
          <div className="pentest-features__item wt-margin-bottom-75">
            <div className="pentest-features__icon">
              <img src={AimIcon} alt="mouse" />
            </div>
            {t(
              "Investigation of discovered vulnerabilities in order to determine the ways of their exploitation"
            )}
          </div>
        </div>
      </div>

      <div className="wt-flex wt-flex-wrap pentest-features__mobile">
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={MouseIcon} alt="mouse" />
          </div>
          {t("Manual vulnerability discovery")}
        </div>
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={ConductingIcon} alt="mouse" />
          </div>
          {t("Conducting tool checks of applications")}
        </div>
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={Shield2Icon} alt="mouse" />
          </div>
          {t("Cyber risks assessment based on the publicly available data")}
        </div>
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={Mouse2Icon} alt="mouse" />
          </div>
          {t(
            "Complementary automated checks by the most reliable vulnerability scanners"
          )}
        </div>
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={NoteIcon} alt="mouse" />
          </div>
          {t(
            "Detailed report with the recommendations to eliminate the identified vulnerabilities"
          )}
        </div>
        <div className="pentest-features__item">
          <div className="pentest-features__icon">
            <img src={AimIcon} alt="mouse" />
          </div>
          {t(
            "Investigation of discovered vulnerabilities in order to determine the ways of their exploitation"
          )}
        </div>
      </div>
    </section>
  )
}

export default PentestFeatures
