import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../sections/header"
import PentestSection from "../../sections/pentest/pentest-main"
import PentestReasonSection from "../../sections/pentest/pentest-reason"
import PentestBenefitsSection from "../../sections/pentest/pentest-benefits"
import PentestOffersSection from "../../sections/pentest/pentest-offers"
import PentestFeaturesSection from "../../sections/pentest/pentest-features"
import PentestTeamSection from "../../sections/pentest/pentest-team"
import PentestFormSection from "../../sections/pentest/pentest-form"
import Footer from "../../sections/footer"

const PentestPage = () => {
  return (
    <Layout>
      <div style={{ overflow: "hidden" }}>
        <SEO title="Web-Application Penetration Testing" />
        <Header theme="dark" />

        <PentestSection />
        <PentestReasonSection />
        <PentestBenefitsSection />
        <PentestOffersSection />
        <PentestFeaturesSection />
        <PentestTeamSection />
        <PentestFormSection />

        <Footer />
      </div>
    </Layout>
  )
}

export default PentestPage
