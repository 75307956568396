import React, { useState, useEffect } from "react"
import "./styles.css"
import ArtImage from "../../../images/pentest_offer_card.svg"
import BlackBoxImage from "../../../images/black_box.png"
import GrayBoxImage from "../../../images/gray_box.png"
import WhiteBoxImage from "../../../images/white_box.png"
import Swiper from "react-id-swiper"
import { useTranslation } from "../../../utils"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useAnalytics } from "../../../hooks/useTracking"

const PentestOffers = () => {
  const { t } = useTranslation()
  const [swiper, updateSwiper] = useState(null)
  const [currentIndex, updateCurrentIndex] = useState(0)
  const analytics = useAnalytics()
  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    effect: "fade",
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    getSwiper: updateSwiper,
  }

  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => updateCurrentIndex(swiper.realIndex))
    }

    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", () => updateCurrentIndex(swiper.realIndex))
      }
    }
  }, [swiper])

  const goTo = index => {
    if (swiper !== null) {
      swiper.slideTo(index)
    }
  }

  return (
    <section className="pentest-offers">
      <div className="container">
        <div className="wt-flex wt-flex-between wt-flex-middle wt-margin-bottom-75">
          <h2 className="pentest-offers__title">
            <FormattedMessage
              id="Our Penetration Testing offers:"
              defaultMessage="Our Penetration Testing offers:"
              values={{
                blue: (...chunks) => (
                  <div className="wt-text wt-text--blue">{chunks}</div>
                ),
              }}
            />
          </h2>

          <div className="pentest-offers__pagination">
            <span
              className={currentIndex === 0 ? "active" : ""}
              onClick={() => {
                analytics.clickChangeTab("Black box")
                goTo(1)
              }}
            >
              {t("Black Box")}
            </span>
            <span
              className={currentIndex === 1 ? "active" : ""}
              onClick={() => {
                analytics.clickChangeTab("Gray box")
                goTo(2)
              }}
            >
              {t("Gray box")}
            </span>
            <span
              className={currentIndex === 2 ? "active" : ""}
              onClick={() => {
                analytics.clickChangeTab("White box")
                goTo(0)
              }}
            >
              {t("White box")}
            </span>
          </div>
        </div>

        <Swiper {...params}>
          <div className="pentest-offers__card">
            <img src={ArtImage} alt="" className="pentest-offers__card-image" />

            <div>
              <h4>{t("Black box")}</h4>
              <p>
                {t(
                  "Testing, which involves both checking the functionality of the system, and its general characteristics. This type does not imply knowledge of the internal structure of the tested information system. Contractor’s specialists act as external attackers. A fairly limited number of hacking paths and possible vulnerabilities are tested and covered."
                )}
              </p>
            </div>

            <img
              src={BlackBoxImage}
              alt="black box image"
              className="pentest-offers__card-box"
            />
          </div>
          <div className="pentest-offers__card">
            <img src={ArtImage} alt="" className="pentest-offers__card-image" />

            <div>
              <h4>{t("Gray box")}</h4>
              <p>
                {t(
                  "Testing, which suggests that pentesters only partially have knowledge of the internal structure of the tested information system. For example, access to information about the internal structure of the system and the algorithms of its operation is allowed, but at the same time, the Contractor’s specialists act from the position of an external attacker. A combination of white and black box penetration testing methods."
                )}
              </p>
            </div>

            <img
              src={GrayBoxImage}
              alt="gray box image"
              className="pentest-offers__card-box"
            />
          </div>
          <div className="pentest-offers__card">
            <img src={ArtImage} alt="" className="pentest-offers__card-image" />

            <div>
              <h4>{t("White box")}</h4>
              <p>
                {t(
                  "Testing, assuming that the Contractor’s specialists have information about the internal structure, architecture, implementation of the tested information system. One of the advantages of this type of testing is a wider and deeper coverage of possible entry points and vulnerabilities in the system."
                )}
              </p>
            </div>

            <img
              src={WhiteBoxImage}
              alt="gray box image"
              className="pentest-offers__card-box"
            />
          </div>
        </Swiper>

        <div className="pentest-offers__footer" />
      </div>
    </section>
  )
}

export default PentestOffers
