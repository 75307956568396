import React from "react"
import classNames from "classnames"
import "./styles.css"

const Input = React.forwardRef(
  ({ children, color, label, className, textarea, ...props }, ref) => {
    const classList = classNames(
      "wt-input__inner",
      { [`wt-input__inner--${color}`]: color },
      { "wt-input__inner--with-icon": children }
    )

    return (
      <div className={classNames("wt-input", className)}>
        {label && (
          <label className="wt-input__label" htmlFor={props.name}>
            {label}
          </label>
        )}

        <div className={classList}>
          {textarea ? (
            <textarea {...props} ref={ref} rows={5} />
          ) : (
            <input {...props} ref={ref} />
          )}

          {children && <div className="wt-input__icon">{children}</div>}
        </div>
      </div>
    )
  }
)

export default Input
